import axios from "axios"
import apiService from "@/_services/api-service.js"
import tokenService from "@/_services/token-service.js"
import store from "@/store"
import router from "@/router"

export default {
    data: () => ({
        returnUrl: '',
        getRoles: false,
        userId: 0,
    }),

    // Call this after successful user login.
    loginSuccess(response, returnUrl) {
        this.returnUrl = returnUrl
        var token = tokenService.parse(response.data)
        const user = {
            id: token.id,
            email: token.email,
            expires: token.expires,
            LoggedIn: true,
            firstname: token.firstname,
            lastname: token.lastname,
            lastLogin: token.lastLogin,
            token: token.token,
        }
        this.userId = token.id
        store.commit("setUser", user)
        var userToken = token.token
        var expires = token.expires
        this.keepToken(userToken, expires)

        this.getRoles = true;
    },

    logoutSuccess() {
        const user = {
            id: 0,
            email: '',
            expires: '',
            LoggedIn: false,
            firstname: '',
            lastname: '',
            lastLogin: '',
            token: '',
        }
        this.userId = 0
        store.commit("setUser", user)
    },

    getUserRoles() {
        if (this.getRoles) {
            this.getRoles = false
            // Now get the users roles
            const url = "account/roles/" + this.userId
            apiService.get(url, this.getRolesSuccess, this.getRolesError)
        }
    },

    getRolesSuccess(result) {
        const roles = result.data.sort((a, b) => (a.id > b.id) ? 1 : -1)
        store.commit("setUserRoles", roles)
        let returnUrl = store.getters.getReturnUrl
        if (returnUrl == undefined) {
            returnUrl = '/'
        }
        router.push(returnUrl)
        store.commit("setReturnUrl", '')
    },

    getRolesError(error) {
        const notification = {
            title: "User Roles",
            error: error.data,
        };
        store.commit("setResponseError", notification);
    },

    getTimeRemaining(endtime) {
        const end = Date.parse(endtime)
        const now = Date.parse(new Date())
        const total = end - now
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
        const days = Math.floor(total / (1000 * 60 * 60 * 24));

        return {
            total,
            days,
            hours,
            minutes,
            seconds
        };
    },

    keepToken(token, expires) {
        const deadline = expires
        const timeInterval = setInterval(() => {
            const t = this.getTimeRemaining(deadline)
            if (t.total <= 0) {
                clearInterval(timeInterval)
                this.refresh(token)
            }
        })
    },

    refresh(token) {
        const url = process.env.VUE_APP_TEMPLATE_BASEURL + 'Account/Refresh/' + token
        return axios.post(url, {}).then(result => {
            if (result.data.statusCode === 200) {
                var token = tokenService.parse(result.data.data)
                var user = store.getters.getUser
                user.token = token.token
                store.commit("setUser", user);
                var userToken = token.token
                var expires = token.expires
                this.keepToken(userToken, expires)
            }
            return result.data
        })
    },

}