<template>
  <div>
    <v-layout>
      <v-row>
        <v-icon class="page-icon" x-large>{{ icon }}</v-icon>
        <h1 class="page-title">{{ title }}</h1>
      </v-row>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "page-title",
  props: ["icon", "title"],
};
</script>

<style lang="scss" scoped>
.page-icon {
  padding-top: 5px;
  padding-left: 10px;
  padding-bottom: 10px;
}
.page-title {
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
}
</style>
