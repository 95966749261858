<template>
  <!-- Logout Component -->
  <v-container>
    <v-card color="primary">
      <v-card-title>
        <pagetitle icon="mdi-logout" title="Logout"></pagetitle>
      </v-card-title>
      <v-card-subtitle
        ><h2>Are you sure you want to logout?</h2></v-card-subtitle
      >
      <v-card-actions>
        <v-row>
          <v-spacer></v-spacer>
          <v-btn outlined @click="onLogout">Logout</v-btn>
          <v-spacer></v-spacer>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import pagetitle from "../../components/controls/Title"
import router from "@/router"
import store from "@/store"
import apiService from "@/_services/api-service.js"
import userService from "@/_services/user-service.js"
export default {
  name: "Account-Logout",

  components: {
    pagetitle,
  },

  methods: {
    onLogout() {
      const url = "account/logout/" + store.getters.getUser.id;
      apiService.post(url, {}, this.logoutSuccess, this.logoutError);
    },

    logoutSuccess() {
      const notification = {
        title: "Logout",
        status: "success",
        messages: ["User logged out"],
      };
      store.commit("setNotification", notification);
      userService.logoutSuccess();
      router.push("/account/login");
    },

    logoutError(error) {
      const notification = {
        title: "Logout",
        error: error.data,
      };
      store.commit("setResponseError", notification);
    },
  },

  router,
  store,
};
</script>
<style lang="scss" scoped>
.v-card {
  margin-right: 15px;
}
.v-btn {
  margin-bottom: 20px;
  margin-left: 10px;
}

</style>
